import styles from "../css/app.pcss";
import { tns } from "tiny-slider/src/tiny-slider";
var SimpleLightbox = require("simple-lightbox");
import Player from '@vimeo/player';
// App main
const main = async () => {
  // Import our CSS
  //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
  // Async load the vue module
  // const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
  // Create our vue instance
  // const vm = new Vue.default({
  //     el: "#app",
  //     components: {
  //         'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
  //     },
  //     data: {
  //     },
  //     methods: {
  //     },
  //     mounted() {
  //     },
  // });
};
// Execute async function
main().then(value => {
  document.getElementById("hamburger").onclick = function() {
    document.getElementsByTagName('body')[0].classList.toggle("opened");
    document.getElementById("hamburger").classList.toggle("open");
  };

  var lightbox = new SimpleLightbox({ elements: ".gallery .gallery-image" });

  var sliderArray = document.querySelectorAll(".banner-slider");
  sliderArray.forEach(function(el) {
    tns({
      container: el,
      mode: "gallery",
      mouseDrag: true,
      controls: false,
      nav: true,
      navPosition: "bottom",
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false
    });
  });


  // intro video
  // reference the elements
  const iframe = document.getElementById('hero_vimeo_player');
  const playButton = document.getElementById('playButton')
  const stopButton = document.getElementById('stopButton')
  const videoOverlay = document.getElementById('videoOverlay')
  // establish iframe as the video
  const player = new Player(iframe);
  // play the video
  player.play(); 
  // create start and stop functions
  const playVideo = () => {
    // set volume to full
    player.setVolume(1);
    // add class which css uses to hide the other elements when playing
    videoOverlay.classList.toggle("playing");
  }
  const stopVideo = () => {
    // must volume
    player.setVolume(0);
    // add class which css uses to show the other elements when on muted
    videoOverlay.classList.toggle("playing");
  }
  // add listners to the buttons
  playButton.addEventListener("click", playVideo);
  stopButton.addEventListener("click", stopVideo);
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
